import React from "react";
import Footer from "../Footer/Footer";

const Terms = () => {
  return (
    <>
      <div className="flex justify-center">
        <div className="container">
          <div className="pt-20 pb-20">
            <h1 className="my-8 py-2 pl-2 text-3xl font-bold">
              Terms and Conditions
            </h1>
            <p className="pb-4">
              Registration for the conference signifies an intent to attend.
              Once registered, delegates are bound by the terms and conditions
              outlined herein. Delegates are responsible for their own conduct
              throughout the conference. Discipline for any misconduct may
              include, but is not limited to, expulsion from the conference
              without reimbursement.{" "}
            </p>
            <p className="pb-4">
              <b className="mr-4">I.</b>During all conference activities,
              delegates are required to uphold the utmost level of
              professionalism, respect, and deplomacy. Discriminatory,
              disrespectful, or disruptive behavior will not be tolerated, as
              outlined in the equity form.{" "}
            </p>
            <p className="pb-4">
              <b className="mr-4">II.</b>Payment for conference registration is
              due upon registration. No refunds will be issued for any reason.
            </p>
            <p className="pb-4">
              <b className="mr-4">III.</b>Delegates consent to adhere to the
              guidelines established by the equity officer at the commencement
              of the conference, by registering. Disciplinary actions may be
              imposed if these guidelines are broken.
            </p>
            <p className="pb-2">
              <b className="mr-4">IV.</b>The items listed below are not
              permitted for any reason during the conference and will be
              confiscated if discovered.
            </p>
            <p className="pb-1">
              <b className="mr-4">a.</b>Alcohol Illegal Substances
            </p>
            <p className="pb-1">
              <b className="mr-4">b.</b>Any type of weapon
            </p>
            <p className="pb-1">
              <b className="mr-4">c.</b>Vaping equipment
            </p>
            <p className="pb-1">
              <b className="mr-4">d.</b>Smoking paraphernalia
            </p>
            <p className="pb-4">
              <b className="mr-4">e.</b>Any other additional items that the
              conference organizers deem unsuitable or unsafe.
            </p>
            <p className="pb-4">
              <b className="mr-4">V.</b>In case of vandalism or damage to school
              property, delegates will be held responsible for the cost of
              repair or replacement.{" "}
            </p>
            <p className="pb-4">
              <b className="mr-4">VI.</b>Discrimination, harassment, or any form
              of disrespectful behavior towards fellow delegates, advisors, or
              conference staff will not be tolerated and may result in immediate
              expulsion from the conference without refund.{" "}
            </p>
            <p className="pb-4">
              <b className="mr-4">VII.</b>Mitini Model United Nations
              organizers, hosts, and partners are not liable for any loss,
              injury, or damage to personal property incurred during the
              conference.
            </p>
            <p className="pb-4">
              <b className="mr-4">VIII.</b>By attending the conference,
              delegates consent to the use of their likeness in promotional
              materials, including but not limited to photographs, videos, and
              social media posts.{" "}
            </p>
            <p className="pb-4">
              <b className="mr-4">IX.</b>Delegates are expected to familiarize
              themselves with the rules and procedures of the conference, as
              outlined in the conference handbook.{" "}
            </p>
            <p className="pb-4">
              <b className="mr-4">X.</b>All intellectual property rights for
              conference materials, including but not limited to background
              guides, logos, and branding, belong to the Mitini Model United
              Nations Conference organizers.{" "}
            </p>
            <p className="pb-4">
              <b className="mr-4">XI.</b>As mentioned in the website whilst
              registering, delegates must mention their full name and phone
              number in the ‘Remarks’ section during the payment procedure. If
              not mentioned, delegates will not hold the right to a refund and
              their participation in the MUN will not be accepted, valid, or
              noted.{" "}
            </p>
            <p className="pb-4">
              <b className="mr-4">XII.</b>Mitini Model United Nations Conference
              organizers reserve the right to amend these terms and conditions
              at any time. Any changes will be communicated to registered
              participants via email and/or on the conference website.{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
