import React from "react";
import Footer from "../../Footer/Footer";

const Marketing = () => {
  return (
    <>
      <div className="pt-28 md:px-14 px-4 py-16 max-w-screen-2xl mx-auto">
        <div className="text-center my-8">
          <h2 className="text-4xl text-neutral-700 font-semibold pb-16">
            The Marketing Team
          </h2>

          <h3 className="text-neutral-700 font-semibold pb-6">The Head</h3>

          <div className="flex justify-center">
            <div className="container">
              <div className="md:flex flex-row pb-6 hidden">
                <div className="basis-1/4"></div>
                <div className="pb-10 basis-1/2">
                  <div className="overflow-hidden">
                    <img
                      src="https://i.ibb.co/YBb7yFF/JOSHI.jpg"
                      className="mx-auto h-[100%] w-[100%]"
                      alt=""
                    />
                  </div>
                  <div className="space-y-1 p-3">
                    <h1 className="line-clamp-1 font-semibold text-xl font-serif text-neutral-700">
                      Pratyush Joshi
                    </h1>
                    <p className="font-serif text-neutral-700">
                      Introducing Pratyush Joshi, or perhaps only Joshi. That’s
                      what all his friends call him by, may it be in Nepal, The
                      Netherlands or South Africa. There are two particular
                      reasons for this, one: its obvious, and two: because he’s
                      full of ‘Josh’. He’s got this lively energy that draws
                      people in, not forgetting his delightful humor, of course
                      (although, his laughter is more funnier than his joke).
                      And beyond his vibrant personality, Joshi is the kind of
                      person you'd want on your team, let it be a project, a
                      startup, a school election, or even a Model United
                      Nations. He's a natural leader who puts his all into every
                      endeavor, ensuring that the journey, if not the outcome,
                      is a resounding success.
                    </p>
                  </div>
                </div>
                <div className="basis-1/4"></div>
              </div>

              {/* small */}
              <div className="md:hidden flex flex-row pb-6">
                <div className="grid grid-cols-1 pb-10">
                  <div className="overflow-hidden">
                    <img
                      src="https://i.ibb.co/YBb7yFF/JOSHI.jpg"
                      className="mx-auto h-[100%] w-[100%]"
                      alt=""
                    />
                  </div>
                  <div className="space-y-1 p-3">
                    <h1 className="line-clamp-1 font-semibold text-xl font-serif text-neutral-700">
                      Pratyush Joshi
                    </h1>
                    <p className="font-serif text-neutral-700">
                      Introducing Pratyush Joshi, or perhaps only Joshi. That’s
                      what all his friends call him by, may it be in Nepal, The
                      Netherlands or South Africa. There are two particular
                      reasons for this, one: its obvious, and two: because he’s
                      full of ‘Josh’. He’s got this lively energy that draws
                      people in, not forgetting his delightful humor, of course
                      (although, his laughter is more funnier than his joke).
                      And beyond his vibrant personality, Joshi is the kind of
                      person you'd want on your team, let it be a project, a
                      startup, a school election, or even a Model United
                      Nations. He's a natural leader who puts his all into every
                      endeavor, ensuring that the journey, if not the outcome,
                      is a resounding success.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid grids-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-16 pb-6">
                <div className="pb-10">
                  <div className="overflow-hidden">
                    <img
                      src="https://i.ibb.co/1qWHVS3/ARNAV-1.jpg"
                      className="mx-auto h-[100%] w-[100%]"
                      alt=""
                    />
                  </div>
                  <div className="space-y-1 p-3">
                    <h1 className="line-clamp-1 font-semibold text-xl font-serif text-neutral-700">
                      Arnav Jung Thapa
                    </h1>
                    <p className="font-serif text-neutral-700">
                      Put your hands together welcoming Arnav! he has got more
                      big ideas than a brainstorming session at a comedy club
                      and enough ambition to power a rocket straight into your
                      heart, With a playlist as diverse as his sense of humor,
                      He is always ready to groove the rhythm of life. He is a
                      god among the mortals, a shining example of goodness and
                      grace for all who are fortunate enough to know him, his
                      energy with unpredictable antics will definitely leave you
                      simultaneously bewildered and impressed. But be sure to
                      buckle up and hold onto your hat, because with Arnav
                      around, the unexpected is to be expected.
                    </p>
                  </div>
                </div>
                <div className="pb-10">
                  <div className="overflow-hidden">
                    <img
                      src="https://i.ibb.co/HYPLtCH/RAGHU-1.jpg"
                      className="mx-auto h-[100%] w-[100%]"
                      alt=""
                    />
                  </div>
                  <div className="space-y-1 p-3">
                    <h1 className="line-clamp-1 font-semibold text-xl font-serif text-neutral-700">
                      Raghu Bir Shrestha
                    </h1>
                    <p className="font-serif text-neutral-700">
                      Introducing one and only Raghu Bir Shrestha! The man who's
                      got a better smile than the smile emoji itself. That's
                      something that cant go unnoticed about him apart from his
                      charismatic personality. He's also the go-to person who's
                      always got your back, you'll never have to expect 'no' for
                      an answer if you're planning to ask him for any favor.
                      Another thing that makes Raghu Raghu is how he can
                      effortlessly bring laughter into every moment in the most
                      unusual ways.In all honesty, he is a gem of a person with
                      simplicity, a humble charm and compassion ingrained in his
                      DNA.
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grids-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-16">
                <div className="">
                  <div className="overflow-hidden">
                    <img
                      src="https://i.ibb.co/R9zBsf8/NORIKO-1.jpg"
                      className="mx-auto h-[100%] w-[100%]"
                      alt=""
                    />
                  </div>
                  <div className="space-y-1 p-3">
                    <h1 className="line-clamp-1 font-semibold text-xl font-serif text-neutral-700">
                      Noriko Gurung
                    </h1>
                    <p className="font-serif text-neutral-700">
                      To be loved by Noriko is to be known. The epitome of good
                      intentions and a pure heart. She is the one you can always
                      rely on, the shoulder that’s always there for you to put
                      your head on. Beautiful sprouts of spring in her smile.
                      Happiness and laughter comes in the room with her. You
                      will catch her taking pictures always, that’s one of her
                      love languages. She has an unexplainable sense of sympathy
                      and understanding. Everyone needs a Nori in their life.
                      There’s nothing or no one she loves more than her friends
                      and family (except ice cream).
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="overflow-hidden">
                    <img
                      src="https://i.ibb.co/FnL0njw/SUYOG.jpg"
                      className="mx-auto h-[100%] w-[100%]"
                      alt=""
                    />
                  </div>
                  <div className="space-y-1 p-3">
                    <h1 className="line-clamp-1 font-semibold text-xl font-serif text-neutral-700">
                      Suyog Acharya
                    </h1>
                    <p className="font-serif text-neutral-700">
                      Guess what, you’ll find Suyog not just in photography but
                      also in the marketing team. Suyog is one of the core
                      member of Mitini Mun's marketing team, blending design
                      expertise with a wealth of marketing skills honed through
                      organizing numerous successful events. With a keen eye for
                      aesthetics and a strategic mindset, Suyog crafts visually
                      captivating campaigns that resonate with audiences and
                      drive engagement. His fusion of design and marketing
                      ensures that Mitini Mun stands out and leaves a lasting
                      impact in the hearts of participants and supporters alike.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Marketing;
